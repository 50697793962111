/* Base input-element styling */
%input-element-base { display: block; width: 100%; min-height: $form-element-height; padding: 5px 10px; border-radius: $border-radius;
    font: $font-size--regular $font-family--primary; border: 0; white-space: normal; background: $color-white;
    border: 1px solid $input-border-color; transition: border-color .15s ease, color .15s ease; }
%faux-input-element-base--focus,
%input-element-base:focus { outline: none; border-color: $input-border-color--focus; }
%faux-input-element-base--disabled,
%input-element-base:disabled { opacity: .5; filter: grayscale(100%); cursor: default; }

%clear-button { padding: 0; border: none; background: none; }

%pieltje { position: relative; }
%pieltje::after { content: ''; position: absolute; bottom: 0; left: 50%; width: 30px; height: 30px; border-radius: 8px;
  transform: translate(-50%, 50%) rotate(45deg); clip-path: polygon(0% 100%, 100% 100%, 100% 0%); background: $color-white; }

%main-container-white { padding: 60px; background: $color-white; border-radius: $border-radius; }

@include mq__mobile {
    %main-container-white { margin: 0 -1*$mobile-margin; padding: $mobile-margin; }
}
