/**
 Taxon dropdown
 */

.taxon-dropdown { position: relative; z-index: 2; }
.taxon-dropdown__trigger { position: relative; z-index: 2; }
.taxon-dropdown__nav { transform: translateY(-555vh); position: absolute; left: -115px; width: calc(100vw - 235px); max-width: 1000px; box-shadow: 0 0 0 999vh rgba($color-black, 0); transition: box-shadow .5s, transform 0s; }

.taxon-dropdown--active .taxon-dropdown__nav { transform: translateY(0); box-shadow: 0 0 0 999vh $color-modal; }


@include mq__mobile {
    .taxon-dropdown__nav { left: -70px; width: 100vw; }
}
