$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-stop-bord: "\e907";
$icon-search: "\e905";
$icon-cart: "\e904";
$icon-arrow: "\e903";
$icon-cross: "\e906";
$icon-checkmark: "\e902";
$icon-home: "\e901";
$icon-minus: "\e652";
$icon-plus: "\e656";
$icon-list: "\e60c";
$icon-left: "\e64c";
$icon-down: "\e654";
$icon-up: "\e655";
$icon-right: "\e64d";
$icon-twitter: "\e65f";
$icon-facebook: "\e661";
$icon-googleplus: "\e663";
$icon-linkedin: "\e669";
$icon-instagram: "\e900";

