/*
 * Import LEVEL 0~1 - Settings and Tools
 */
@import '_settings';

$icomoon-font-path: '../icomoon/fonts';
@import '../../fonts/icomoon/style.scss';

/*
 * LEVEL 2 - Generic
 *
 * Define generics to reset project
 */
@import '2-generic/normalize';
@import '2-generic/reset';
@import '2-generic/custom-properties';
@import '2-generic/custom-media';

/*
 * LEVEL 3 - Elements
 *
 * Define html elements, the basics
 */
@import '3-elements/typography';
@import '3-elements/form-elements';

/*
 * LEVEL 4 - Objects
 *
 * Define reused objects like layouts
 */
@import '4-objects/animations';
@import '4-objects/app-layout';
@import '4-objects/content-layout';
@import '4-objects/grid';
@import '4-objects/lists';
@import '4-objects/wysiwyg';


/*
 * LEVEL 5 - Components
 *
 * Define custom components
 */
@import '5-components/breadcrumbs';
@import '5-components/buttons';
@import '5-components/checkout';
@import '5-components/dropdown';
@import '5-components/forms-twig-based';
@import '5-components/messages';
@import '5-components/navigation';
@import '5-components/page-title';
@import '5-components/product-box';
@import '5-components/product-review';
@import '5-components/rating';
@import '5-components/search';
@import '5-components/tables';
@import '5-components/tabs';
@import '5-components/taxon-tree';
@import '5-components/footer';
@import '5-components/credits';
@import '5-components/usps';
@import '5-components/custom-file-uploader';
@import '5-components/pager';
@import '5-components/cart-indicator';
@import '5-components/shop-header';
@import '5-components/main-search';
@import '5-components/homepage';
@import '5-components/slider';
@import '5-components/taxon-index';
@import '5-components/banners';
@import '5-components/taxon-dropdown';
@import '5-components/taxon-nav';
@import '5-components/login-page';
@import '5-components/taxon-desc';
@import '5-components/product-cell';
@import '5-components/fallback-image';
@import '5-components/modal';
@import '5-components/top-banner';
