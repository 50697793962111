/*
 * LEVEL 0~1 - Settings & Tools
 *
 * SASS variables etc. (not for custom properties)
 * This block should not output any css
 * We import functions and mixins first to be able to use color modifying in variables
 */
@import '0-settings/sass-variables';

@import '1-tools/mixins';
@import '1-tools/functions';
@import '2-generic/placeholder-selectors';
