/**
 Basic lists
 */

/**
 List definition, also used in wysiwyg-ul and wysiwyg-ol.
 */
%list { display: block; }
%list li { position: relative; padding-left: 1em;  }
%list li:before { content: '\e64d'; position: absolute; left: 0; top: .5em; color: currentColor; font-size: $readon-arrow-size; }

/**
  Basic list
 */
.list { @extend %list; }
.list li { margin-bottom: .25em;  }
.list ul,
.list ol { margin-top: .25em;  }

/**
 List checkmarks
 */
.list--checkmarks {  }
.list--checkmarks li { padding: .5em 0 .5em 32px; text-transform: uppercase; font-weight: $font-weight--bold; }
.list--checkmarks li::before,
.list--checkmarks li::after {  position: absolute; top: 3px; left: 0; width: 22px; height: 22px; color: $color-white; }
.list--checkmarks li::before { z-index: 1; text-align: center; line-height: 22px; font-size: 10px; }
.list--checkmarks li::after { content: ''; background: $color-red; border-radius: 4px; transform: rotate(-10deg);  }

/**
 List inline
 */
.list--inline { display: flex; align-items: center; flex-wrap: wrap; }
.list--inline li { width: auto; margin-right: 2em; margin-bottom: initial; }
.list--inline li:last-child { margin-right: 0; }

/**
 List without bullets
 */
.list--no-bullets li { padding-left: 0; }
.list--no-bullets li::before { content: none; }

/**
 List bullets on links
 */
.list--bullets-on-links li { padding-left: 0; margin-bottom: 0; }
.list--bullets-on-links li::before { content: none; }
.list--bullets-on-links li a { display: inline-block; position: relative; padding-left: 1em; padding-bottom: .25em; }
.list--bullets-on-links.list--inline li a { padding-bottom: 0; }
.list--bullets-on-links li a::before { content: '\e64d'; position: absolute; left: 0; top: .6em; color: currentColor; font-size: .6em; }


/**
 List flex-end
 */
.list--flex-end { justify-content: flex-end; }
