/**
 Top-banner
 */

.top-banner { padding: 6px 0; background: $color-red; }
.top-banner__item {
    display: block; padding: 7px 0; color: $color-white; text-align: center;
    a {
        text-decoration: underline;
        &:hover,
        &:focus {
            color: currentColor;
            text-decoration: none;
        }
    }
}

/**
 * Media queries
 */

@include mq__mobile {

}
