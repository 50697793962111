/**
 Credits
 */

.credits { margin-top: 1em; padding: 20px 0; border-top: 1px solid $color-border; font-size: 13px; color: $color-gray--dark; }
.credits a:hover { color: $color-red; }
.credits__nedbase { margin-left: auto; }
.credits__nedbase a { margin-left: 2em; }



/**
 * Media queries
 */

@include mq__mobile {
  .credits__nedbase { margin-left: 0; }
}
