/**
 Dropdowns
 */
.dropdown { position: relative; }
.dropdown__trigger { cursor: pointer; }
.dropdown__items { position: absolute; z-index: 1; left: 0; top: 100%; border: solid 1px $color-border; overflow: hidden; border-radius: $border-radius;
  background: $color-background; box-shadow: $box-shadow; pointer-events: none;
  transform-origin: 100% 0; transform: scaleY(.6); opacity: 0; transition: transform 80ms linear, opacity 80ms 20ms linear;
}
.dropdown__item { display: block; padding: 13px 25px; white-space: nowrap; cursor: pointer; }
.dropdown__item:hover { background: $color-gray--light; }

/* open dropdown */
.dropdown--open .dropdown__items { pointer-events: all; transform: none; opacity: 1; transition: transform 80ms linear, opacity 80ms linear; }

/* align dropdown__items left */
.dropdown--left .dropdown__items { right: 0; left: auto; }

/* slide-in items in dropdown */
.dropdown--slide-in {
  .dropdown__item { transform: translateX(80px); opacity: 0; }
  &.dropdown--open .dropdown__item { animation: slideInFromRight .15s ease-out forwards; }
  @for $i from 1 through 10 {
    .dropdown__item:nth-child(#{$i}n) { animation-delay: #{($i - 1) * .075}s; }
  }
}

@keyframes slideInFromRight {
  0% { transform: translateX(80px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1;  }
}
