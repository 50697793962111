%button { display: inline-block; position: relative; padding: 14px 15px; padding-right: 40px; background: $color-primary;
  border: 0; border-radius: $border-radius; transition: all .18s ease;
  color: $color-primary--contrast; font-weight: $font-weight--bold; text-decoration: none;
  white-space: nowrap; cursor: pointer; text-align: center; line-height: $line-height--base;
  box-shadow: 0 -1px 0 0 rgba(black, .4) inset; }
%button::after { content: '\e64d'; position: absolute; top: 50%; right: 15px; transform: translateY(-50%); font-size: $readon-arrow-size; }
%button:hover,
%button:focus { background: $color-primary--shade; color: $color-primary--contrast; outline: none; }
%button:active { transform: scale(.98); outline: none; }
%button::first-letter { text-transform: uppercase; }
%button:disabled { filter: grayscale(1); opacity: .35; cursor: not-allowed; }

.button { @extend %button; }
[type=submit] { @extend %button; }

.button--light { background: $color-gray--light; color: $color-black; }
.button--light:hover,
.button--light:focus { background: $color-gray--mid-half; color: $color-black; }

.button--secondary { background: $color-secondary; color: $color-white; box-shadow: none; }
.button--secondary:hover,
.button--secondary:focus { background: $color-secondary--shade; color: $color-white; }

.button--semi-black { background: rgba($color-black, .5); color: $color-white; }
.button--semi-black:hover,
.button--semi-black:focus { background: rgba($color-black, .75); }

.button--small { padding-top: $spacing--50; padding-bottom: $spacing--50; padding-left: $spacing--50; }

.button--full { width: 100%; }

.button--icon::before { display: inline-block; position: relative; margin-right: 1em; transform: scale(1.5); }
.button--icon:not([class*='icon-'])::before { content: '\e64d'; }

.button--no-arrow { padding-right: 15px; }
.button--no-arrow::after { content: none; }
.button--arrow-down::after { content: '\e654'; }

/* button visualized as standard link */
.button-as-link { padding: 0; margin: 0; background: none; border: none; color: $color-black; font-weight: $font-weight--regular; font-size: $font-size--regular; cursor: pointer; }
.button-as-link:hover { color: $color-red; }
.button-as-link--readon::before { content: '\e64d'; margin-right: 7px; font-size: $readon-arrow-size; }
