/**
 Product review
 */
.product-review { display: block; }
.product-review__title { font-weight: bold; }


/**
 List of product-reviews
 */
.product-reviews > .product-review { margin-bottom: $spacing; }
