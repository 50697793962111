html { box-sizing: border-box; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
*, *:before, *:after { box-sizing: inherit; }

//reset icomoon-font-family on all icon-classes, but place font-family on all befores and afters
[class^="icon-"], [class*=" icon-"] { font-family: inherit !important; }
*::after,
*::before { font-family: $font-family--icons; font-weight: $font-weight--regular; }

html { font-size: 62.5%; }
html, body { min-height: 100vh; margin: 0; padding: 0; hyphens: auto; }
img, embed, object, video, iframe { display: inline-block; max-width: 100%; height: auto; }
body figure { margin: 0; }

ul, ol { margin: 0; padding: 0; list-style: none; }
table { width: 100%; border-collapse: collapse; }

address { font-style: normal; }
