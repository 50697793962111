/**
 Taxon index
 */
$gap: 8px;
.taxon-box { display: flex; flex-direction: column; align-items: center; padding: 40px 20px; background: $color-white; border-radius: $border-radius; transition: box-shadow .15s; }
.taxon-box__title { margin-bottom: 30px; font-size: 15px; font-weight: bold; text-transform: uppercase; text-align: center; color: $color-black; transition: color .15s; }
.taxon-box__image { flex-grow: 0; flex-shrink: 0; display: flex; align-items: center; }
.taxon-box__image img { display: block; }

.taxon-box:hover { position: relative; box-shadow: 0 0 0 .25*$gap white, 0 5px 20px 0 rgba($color-black, .25); }
.taxon-box:hover .taxon-box__title { color: $color-red; }

.taxon-box--small {
  flex-direction: row; align-items: flex-start; padding: 15px 20px 25px;
  .taxon-box__title { order: 2; flex-grow: 1; margin: 13px 0 0; padding-right: 8px; position: relative; text-align: left; }
  .taxon-box__title::after { content: '\e64d'; font-size: $readon-arrow-size; position: absolute; top: 3px; right: 0;  }
  .taxon-box__image { justify-content: center; width: 80px; margin-right: 20px; }
}


/**
 Taxon box grid
 */

.taxon-box-grid { display: flex; flex-wrap: wrap; margin: 0 -.5*$gap; }
.taxon-box-grid--center { justify-content: center; }
.taxon-box-grid .taxon-box { width: calc(20% - #{$gap}); margin: 0 .5*$gap $gap; }

.taxon-box-grid--3col .taxon-box { width: calc(33.333% - #{$gap}); }


/**
 * Media queries
 */

@include mq($max-width: 1170px) {
  .taxon-box-grid .taxon-box { width: calc(25% - #{$gap}); }
}
@include mq__tablet {
  .taxon-box-grid .taxon-box { width: calc(33.33% - #{$gap}); }
}
@include mq__mobile {
  .taxon-box-grid .taxon-box { width: calc(50% - #{$gap}); }
}
@include mq($max-width: 500px) {
  .taxon-box-grid .taxon-box { width: calc(100% - #{$gap});  }
}
