/**
 Usps
 */

.usps { display: flex; flex-wrap: wrap; justify-content: space-around; padding: 30px 0; }
.usps__seperator { width: 1px; height: auto; margin: 10px 0; background: rgba($color-blue--dark, .5); }

.usp { display: flex; justify-content: center; align-items: center; padding-left: 15px; }
.usp__img { display: flex; flex-shrink: 0; width: 64px; height: 64px; border-radius: 4px; transform: rotate(10deg); background: rgba(white, .3); transition: .15s; }
.usp__img img { transform: rotate(-10deg); margin-left: -15px; transition: .25s; }
.usp__title { margin-left: 25px; max-width: 150px; font-size: 15px; font-weight: bold; line-height: $line-height--wysiwyg; }
.usp__title::after { content: ''; display: inline-block; width: 1em; height: .6em; background: url(../../images/chevron.svg) no-repeat bottom right / contain; transition: transform .15s;}

.usp:hover {
  color: currentColor;
  .usp__title:after { transform: translateX(5px); }
  .usp__img { transform: rotate(-10deg); }
  .usp__img img { transform: rotate(10deg); }
}


/**
 * Media queries
 */

@include mq($max-width: 890px) {
    .usps { padding: $mobile-margin 3*$mobile-margin; flex-direction: column; align-items: flex-start; }
    .usp__title { max-width: none; }
    .usp__img { width: 32px; height: 32px; }
}
@include mq__mobile {
    .usps { padding: $mobile-margin; }
}
