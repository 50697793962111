/**
 Layout of the content section, section-elements must be available as block in de templates.
 */
.content-layout { display: flex; flex-wrap: wrap; justify-content: space-between; }
.content-layout__header { width: 100%; margin-bottom: $spacing; }
.content-layout__header:empty { display: none; }
.content-layout__main { width: 70%; flex-grow: 1; }
.content-layout__sidebar { width: 30%; padding-left: $spacing; }
.content-layout__sidebar:empty { display: none; }

/* modifiers */
.content-layout--small { margin: 0 25%; }

.content-layout--sidebar-equals-main .content-layout__main,
.content-layout--sidebar-equals-main .content-layout__sidebar { width: 50%; }

.content-layout--sidebar-left .content-layout__header { order: -2; }
.content-layout--sidebar-left .content-layout__sidebar { order: -1; padding-left: 0; padding-right: $spacing; }


@include mq__mobile {
    .content-layout__header { margin: 0; padding: 2*$mobile-margin 0; }

    .content-layout__sidebar,
    .content-layout--sidebar-left .content-layout__sidebar { width: 100%; padding-right: 0; order: 1; margin-top: 2*$mobile-margin;  }
}
