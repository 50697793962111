@keyframes spinner-animation {
  0%,
  50%,
  100% {
    animation-timing-function: cubic-bezier(.5, .5, .5, .5);
  }
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes button-flasher {
  0%,
  100% {
    animation-timing-function: cubic-bezier(.5, .5, .5, .5);
  }
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}

@keyframes scale-slowly {
  0%,
  50%,
  100% {
    animation-timing-function: cubic-bezier(.5,.5,.5,.5);
  }
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.8);
  }
  100% {
    transform: scale(1);
  }
}
