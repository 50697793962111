/**
 Banners
 */

.banner { display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; padding: 30px; background: $color-gray--mid; }
.banner__title { max-width: 310px; margin-bottom: 2em; font-size: 20px; font-weight: $font-weight--bold; }

$gap: 30px;
.banner-grid { display: flex; flex-wrap: wrap; margin: 0 -.5*$gap; }
.banner-grid .banner { width: calc(50% - #{$gap}); margin: .5*$gap; }


/**
 * Media queries
 */

@include mq__mobile {
  .banner-grid .banner { width: 100%; margin: 0 0 .5*$gap 0; }
  .banner-grid .banner:last-child { margin-bottom: 0; }
}
