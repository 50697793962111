@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?wbrhe8') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wbrhe8') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wbrhe8') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wbrhe8##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-stop-bord {
  &:before {
    content: $icon-stop-bord; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-left {
  &:before {
    content: $icon-left; 
  }
}
.icon-down {
  &:before {
    content: $icon-down; 
  }
}
.icon-up {
  &:before {
    content: $icon-up; 
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-googleplus {
  &:before {
    content: $icon-googleplus; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}

