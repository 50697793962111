/**
 Rating
 */
.rating { display: flex; }
%rating__star { width: 15px; height: 15px; border: 2px solid $color-primary; background: transparent; border-radius: 100%; }
.rating__star { @extend %rating__star; }
.rating__star:not(:last-child) { margin-right: 5px; }
.rating__star--active { background: $color-primary--tint; }

/**
 Add a rating widget (based on simple radios)
 */
.rating-radios { display: flex; }
.rating-radios [type=radio] + label { padding-left: 20px; text-indent: -999em; }
.rating-radios [type=radio] + label:before { @extend %rating__star; }
.rating-radios [type=radio] + label:after { content: none; }
.rating-radios [type=radio]:checked + label:before { background: $color-primary--tint; }
