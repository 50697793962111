/**
 Footer
 */

.footer { padding-top: 100px; line-height: $line-height--wysiwyg; }
.footer__list-title { margin-bottom: 2em; color: $color-red; font-size: 15px; }
.footer__contact-link { color: $color-red; }
.footer__logo { float: right; }
.footer .grd__bx { margin-bottom: 60px; }


/**
 * Media queries
 */

@include mq($max-width: 890px) {
  .footer__logo { float: none; }
}


@include mq__mobile {
    .footer { padding-top: 2em; }
    .footer__list-title { margin-bottom: 1em; }
    .footer .grd__bx { margin-bottom: 2em; }

}
