/**
 Product box
 */
$gap: 8px;
.product-box { display: flex; padding: 15px 11px; background: $color-white; border-radius: $border-radius; transition: box-shadow .15s; }
.product-box__image { flex-shrink: 0; flex-basis: 100px; }
.product-box__image img { display: block; width: auto; margin: 0 auto; }
.product-box__text { display: flex; flex-direction: column; flex-grow: 1; padding: 10px 10px 0; font-size: 12px; color: $color-gray--dark; line-height: $line-height--wysiwyg; }
.product-box__title { padding-right: 8px; position: relative; font-size: 14px; font-weight: bold; text-transform: uppercase; color: $color-black; transition: color .15s; }
.product-box__title::after { position: absolute; top: 3px; right: 0; content: '\e64d'; font-size: $readon-arrow-size; }
.product-box__variants { margin-top: 1em; }
.product-box__price { margin-top: auto; }

.product-box:hover { position: relative; box-shadow: 0 0 0 .25*$gap white, 0 5px 20px 0 rgba($color-black, .25); }
.product-box:hover .product-box__title { color: $color-red; }

/**
 Product box grid
 */

.product-box-grid { display: flex; flex-wrap: wrap; margin: 0 -.5*$gap; }
.product-box-grid .product-box { width: calc(33.333% - #{$gap}); margin: 0 .5*$gap $gap; }


/**
 * Media queries
 */

@include mq__tablet {
  .product-box-grid .product-box { width: calc(50% - #{$gap}); }
}
@include mq__mobile {
  .product-box-grid .product-box { width: calc(100% - #{$gap}); }

  .product-box__image { width: 80px; }
  .product-box__variants { display: none; }
}
