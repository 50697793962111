/**
 Pagination
 */

.pager { display: flex; align-items: center; }
.pager__btn { position: relative; display: inline-flex; align-items: center; justify-content: center; margin-left: -1px; min-width: 30px; height: 30px; border: 1px solid $color-border; text-align: center; border-radius: $border-radius; }
.pager__btn[href]:hover,
.pager__btn--active { background-color: $color-gray--mid-half; color: currentColor; }
.pager__btn--active { font-weight: $font-weight--bold; }
.pager__btn--disabled { color: $color-gray--mid; }
.pager__btn--to-first { margin-right: 10px; }
.pager__btn--to-first::before { content: '\e64c'; font-size: 11px; }
.pager__btn--to-last { margin-left: 10px; }
.pager__btn--to-last::before { content: '\e64d'; font-size: 11px; }
.pager__seperator { margin: 0 10px; }
