body,
input,
select,
option,
button,
textarea { font: #{$font-size--regular} / #{$line-height--base} $font-family--primary; }

h1,
h2,
h3,
h4,
h5,
h6 { margin: 0; font-family: $font-family--secondary; font-weight: $font-weight--semi-bold; }

h1 { font-size: 20px; font-weight: bold; }
h2 { font-size: 15px; }
h3 { font-size: 14px; }
h4 { font-size: 14px; }
h5 { font-size: 14px; }
h6 { font-size: 14px; }

p { margin: 0; }

a { color: currentColor; text-decoration: none; }
a:hover,
a:active { color: $color-red; }

dl { display: flex; flex-wrap: wrap; margin: 0; }
dl dt,
dl dd { flex-shrink: 1; width: 30%; margin: 0 0 .6em; }
dl dd { width: 70%; padding-left: 1em; }

hr { height: 2px; margin: 1em 0; border: none; background: $color-border; }
