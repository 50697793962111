/**
 Login
 */
.login-page { @extend %main-container-white; display: flex; justify-content: space-between; }
.login-page__col:last-child { flex-shrink: 0; max-width: 260px; margin-left: 60px; }
.login-page__subheader { margin-bottom: 2em; }
.login-page__subheader h2 { margin-bottom: 2em; }
.login-page__subheader p { line-height: $line-height--wysiwyg; }

@include mq__mobile {
    .login-page { display: block; }
    .login-page__col:last-child { max-width: none; margin-left: 0; }
}
