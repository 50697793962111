/* tables */

.table { width: 100%;}
.table__row { display: flex; padding: 15px 20px; border-radius: $border-radius; }
.table__row--header { font-weight: bold; }
.table__row--center { align-items: center; }
.table__cell { flex-grow: 1; flex-shrink: 1; flex-basis: 100%; padding-right: 30px; min-width: 0; }
.table__cell:last-child { padding-right: 0; }
.table__cell--right { text-align: right; }
.table__cell--center { text-align: center; }
.table__cell--flex-end { display: flex; justify-content: flex-end; align-self: flex-start; }
@each $size in (30,50,80,100,150,200,300) {
  .table__cell--#{$size}px { max-width: #{$size}px; }
}
@each $size in (2,3,4,5) {
  .table__cell--#{$size}x { flex-basis: $size*100%; }
}

/* bordered table */

.table--bordered { border-left: 1px solid $color-border; border-top: 1px solid $color-border; }
.table--bordered .table__cell { border-right: 1px solid $color-border; border-bottom: 1px solid $color-border; padding: $spacing--50; }


@include mq__tablet {
    .table__row { flex-wrap: wrap; padding: 0; }
    .table__cell { margin: .5*$mobile-margin $mobile-margin; padding: 0; flex-basis: auto; }
}
