/*
 * Color definitions
 */

// named colors
$color-white: #fff;
$color-black: #231F20;
$color-gray: #dfe1e0;
$color-gray--dark: #6D7882;
$color-gray--mid: #A6B6C3;
$color-gray--mid-half: #d3dae1;
$color-gray--light: #E7EBEE;
$color-red: #FA441D;
$color-yellow: #f5f263;
$color-green: #81d152;
$color-orange: #ff9d4f;
$color-blue: #5AB2F6;
$color-blue--shade: #5AB2F6;
$color-blue--dark: #293C4A;

// contextual colors
$color-primary: $color-red;
$color-primary--contrast: $color-white;
$color-secondary: $color-blue;
$color-secondary--contrast: $color-white;
$color-modal: rgba($color-black, .35);

// shades and tints
$color-primary--tint: lighten($color-primary, 5);
$color-primary--shade: darken($color-primary, 5);
$color-secondary--tint: lighten($color-secondary, 5);
$color-secondary--shade: darken($color-secondary, 5);

// validation colors
$color-error: $color-red;
$color-warning: $color-yellow;
$color-success: $color-green;
$color-info: $color-blue;

// layout colors
$color-border: $color-gray--mid;
$color-border--dark: darken($color-border, 15);
$color-background: $color-white;
$color-background--shade: $color-gray--light;
$box-shadow: 0 2px 4px rgba(black, .15);

// form colors
$input-border-color: $color-border;
$input-border-color--focus: $color-border--dark;


/*
 * Other definitions
 */

// dimensioning
$border-radius: 3px;
$spacing: 20px;
$spacing--250: #{$spacing * 2.5};
$spacing--200: #{$spacing * 2};
$spacing--150: #{$spacing * 1.5};
$spacing--75: #{$spacing * .75};
$spacing--50: #{$spacing * .5};
$spacing--25:  #{$spacing * .25};
$spacing--header-height: 100px;
$mobile-margin: 10px;

// typography settings
$font-family--primary: Muli, sans-serif;
$font-family--secondary: $font-family--primary;
$font-family--icons: 'icomoon';
$font-size--regular: 14px;
$font-weight--regular: 400;
$font-weight--semi-bold: 600;
$font-weight--bold: 800;
$line-height--base: 1;
$line-height--wysiwyg: 1.5;
$readon-arrow-size: 9px;
$form-element-height: 36px;

/**
 Breakpoints
 */
$breakpoint-mobile: 720px;
$breakpoint-tablet: 1024px;
$breakpoint-bigscreen: 1280px;
