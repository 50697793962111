/**
 App skeleton
 */

.app { display: flex; flex-direction: column; min-height: 100vh; background: $color-background--shade; }
.app__top { padding: 13px 0; display: flex; background: $color-blue; }
.app__header { position: relative; z-index: 2; background: $color-background; }
.app__navbar { border-top: 1px solid $color-border; border-bottom: 1px solid $color-border; background: $color-background; }
.app__main { position: relative; z-index: 1; padding-bottom: 2*$spacing; }
.app__main--no-padding-bottom { padding-bottom: 0; }
.app__main--small .app__container { max-width: 840px; }
.app__modal { position: fixed; z-index: 3; top: 0; left: 0; }
.app__footer { position: relative; z-index: 1; margin-top: auto; background: $color-background;}
.app__usps { padding: 15px 0; background: $color-blue; }
.app__container { margin: 0 auto; width: calc(100% - #{$spacing}); max-width: 1200px; }


/* shop-top */
.app__top .dropdown { margin-right: $spacing; }
.app__top .security-menu { margin-left: auto; }
.app__top .security-menu a:hover { color: $color-blue--dark; }

/**
 * Media queries
 */

@include mq__mobile {
    .app__main {  padding-bottom: 2*$mobile-margin; }
}

