/**
 Basic grid system
 */

.grd { display: flex; flex-wrap: wrap; margin: 0 -10px; }
.grd__bx { width: calc(33.333% - 20px); margin: 0 10px 20px; }

.grd--2col .grd__bx { width: calc(50% - 20px); }
.grd--4col .grd__bx { width: calc(25% - 20px); }


@include mq__tablet {
  .grd__bx,
  .grd--2col .grd__bx,
  .grd--4col .grd__bx { width: calc(50% - 20px); }
}


@include mq__mobile {
  .grd { margin: 0 0 20px; }
  .grd:last-child { margin: 0; }

  .grd__bx { margin: 0 0 20px 0; }
  .grd__bx:last-child { margin-bottom: 0; }

  .grd__bx,
  .grd--2col .grd__bx,
  .grd--4col .grd__bx {  width: 100%; }
}
