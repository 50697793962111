/**
 Taxon description
 */

.taxon-desc { position: relative; padding-left: 60px; margin-bottom: 60px; min-height: 100px; }
.taxon-desc::before { content: ''; position: absolute; left: 0px; width: 6px; height: 100px; border-radius: 0 3px 3px 0; background: $color-red;  }
.taxon-desc__title { padding: 1.5em 0; font-weight: bold; }
.taxon-desc__text { line-height: $line-height--wysiwyg; }


@include mq__mobile {
    .taxon-desc { margin: $mobile-margin 0; padding: 0 2*$mobile-margin; }
}
