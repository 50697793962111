/**
 Page titles and subtitles
 */

.page-title { display: block; margin-top: 50px; font-size: 20px; font-weight: bold; }
.page-title:not(:last-child) { margin-bottom: 50px; }
.page-title__h1 { display: inline; }
.page-title__sub { padding-top: $spacing; margin-bottom: 50px; }

.page-title--indented { margin-left: 60px; }


@include mq__mobile {
    .page-title { margin-top: 0; }
    .page-title:not(:last-child) { margin-bottom: $mobile-margin; }
    .page-title--indented { margin-left: 0; }
}
