/**
 Breadcrumbs
 */

.breadcrumb { padding: 50px 0; line-height: $line-height--wysiwyg; }
.breadcrumb__item { display: inline-block; position: relative; margin-right: 40px; font-size: 20px; font-weight: bold; text-decoration: underline; }
.breadcrumb__item::after { content: '\e903'; position: absolute; right: -30px; top: .25em; color: $color-gray--dark; font-size: 70%; pointer-events: none; }
.breadcrumb__item--active { margin-right: 0; padding-right: 0; text-decoration: none;  }
.breadcrumb__item--active:after { content: none; }

@include mq__mobile {
    .breadcrumb { padding: 2*$mobile-margin 0; }
    .breadcrumb__item { font-size: $font-size--regular; }
}
