/**
 Taxon-tree
 */
.taxon-tree { display: flex; flex-direction: column; }
.taxon-tree__item { position: relative; }
.taxon-tree__cat { margin-left: 1em; }
.taxon-tree__link { display: block; padding: $spacing--50 $spacing; padding-left: 0;  }

.taxon-tree--inline { flex-direction: row; }
.taxon-tree--inline .taxon-tree__cat { margin-left: auto; position: absolute; top: 100%; left: 0; display: none; }
.taxon-tree--inline .taxon-tree__item--has-subs:hover > .taxon-tree__cat { display: block; }
