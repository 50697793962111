/**
 Main search
 */
.main-search__form { display: flex; border: 1px solid $color-border; border-radius: $border-radius; }
.main-search__form:focus-within { @extend %faux-input-element-base--focus; }
.main-search__input { border: none; font-size: 15px; }
.main-search__button { @extend %clear-button; padding: 0 10px; min-height: 40px; color: $color-border; }
.main-search__button::after { content: '\e905';  font-size: 20px; }
.main-search__button--toggle { display: none; }


@include mq__mobile {
  .main-search { position: static; }
  .main-search__form { position: absolute; z-index: 1; top: 70px; left: 10px; right: 10px; background: $color-white; transform: scaleY(0); opacity: 0; transform-origin: 50% 0; transition: .25s; }
  .main-search__form--open { transform: scaleY(1); opacity: 1; }
  .main-search__button--toggle { display: flex; align-items: center; border: 1px solid $color-border; border-radius: $border-radius; }
  .main-search__button--toggle-close::after { content: '\e906'; }
}
