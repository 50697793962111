/**
 Taxon nav
 */

.tax-nav { display: flex; overflow: hidden; border-radius: $border-radius; background: $color-white; }
.tax-nav__back-btn { display: none; padding: 0; margin: 0; border: none; width: 35px; height: 35px; line-height: 35px; background: $color-gray--mid; color: $color-white; border-radius: 100%; }
.tax-nav__back-btn::after { content: '\e64c'; }

.tax-nav__mains-container { width: 283px; flex-shrink: 0; display: flex; flex-direction: column; padding: 20px 0 60px 15px; transition: margin .15s; }
.tax-nav__main-item { position: relative; padding: 13px 20px; font-weight: $font-weight--bold; }
a.tax-nav__main-item--active { background: $color-gray--light; color: $color-black; }

.tax-nav__subs-container { position: relative; flex-grow: 1; padding: 20px 0 20px 15px; background: $color-gray--light; }
.tax-nav__subs { display: none; }
.tax-nav__subs--active { display: flex; flex-wrap: wrap; background: $color-gray--light;}
.tax-nav__sub-item { padding: 13px 20px; width: 230px; }

.tax-nav__subs-footer { width: 100%; padding: 13px 20px; }

@include mq__mobile {
    .tax-nav__back-btn { display: inline-block; position: absolute; top: 10px; left: 10px; transition: transform .15s; }
    .tax-nav__mains-container,
    .tax-nav__subs-container { width: 100%; padding: 10px 0 30px 10px; }
    .tax-nav__subs-container { padding-left: 55px; }
    .tax-nav--subs-visible .tax-nav__mains-container { margin-left: -100%; }
}
