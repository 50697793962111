/**
 Homepage
 */
.homepage__slider { @extend %pieltje; position: relative; background: $color-white; }
.homepage__slider::before { content: ''; width: 100%; height: 4px; position: absolute; box-shadow: 0 4px 4px -4px $color-gray--dark inset; }
.homepage__banners { padding: 70px 0 100px; background: $color-white; }
.homepage__banners .banner-grid { margin: 0 70px; }
.homepage__taxon-index { padding: 70px 0; }


/**
 * Media queries
 */

@include mq__mobile {
    .homepage__slider { margin-bottom: 20px; }
    .homepage__banners { padding: $mobile-margin 0; }
    .homepage__banners .banner-grid { margin: 0; }
    .homepage__taxon-index { padding: $mobile-margin 0; }
}
