/**
 Modal
 */

.modal { width: 100%; height: 0; overflow-y: auto; overflow-x: hidden; display: flex; justify-content: flex-end; align-items: stretch; background: rgba($color-black, 0); transition: height 0s .5s, background .55s;  }
.modal__content { width: 45%; max-width: 500px; overflow-y: auto; transform: translateX(100%); background: $color-white; transition: .25s; }
.modal__close { display: none; position: absolute; padding: 5px; top: 30px; left: 55px; cursor: pointer; background: none; border: none; }
.modal__close::before { content: '\e906'; color: $color-red; font-size: 20px; }
.modal--open {
    background: $color-modal; height: 100%; transition-delay: 0s, 0s;
    .modal__content { transform: translateX(0); }
    .modal__close { display: block; }
}


/**
 * Media queries
 */

@include mq__mobile {
    .modal__content { width: 100%; max-width: none; padding: 0 $mobile-margin; }
    .modal__close { left: 10px; top: 10px; }
}
