/*
 * Custom file uploader
 */

.custom-file-uploader-container { width: 100%; background: $color-blue; color: $color-white; border-radius: $border-radius; }
.custom-file-uploader { position: relative; display: flex; align-items: center; cursor: pointer; min-height: 0; padding: 5px 15px; z-index: 1; }
.custom-file-uploader--focus { @extend %faux-input-element-base--focus; }
.custom-file-uploader__label { display: block; flex-grow: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 14px; font-weight: $font-weight--bold; }
.custom-file-uploader__button { @extend %button; padding-top: 7px; padding-bottom: 7px; }
.custom-file-uploader__button { visibility: hidden; padding-left: 0; padding-right: 0; width: 0; overflow: hidden; }
.custom-file-uploader > input[type=file] { position: absolute; width: 1px; height: 1px; top: 50%; right: 48px; z-index: -1; outline: none; }
.custom-file-uploader > input[type=file]:focus ~ .custom-file-uploader__button { outline: none; border-color: darken($color-border, 8%); }


/*
 * Selected file
 */
.selected-file { display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 5px 5px 5px 15px; background: $color-gray--light; border-radius: $border-radius;  }
.selected-file__del-button { flex-shrink: 0; margin-left: 20px; border: none; width: 26px; height: 26px; line-height: 26px; font-size: 12px; background: transparent; border-radius: 100%; color: $color-gray--mid; cursor: pointer; }
.selected-file__del-button:hover { color: $color-gray--dark; }
