/* tabs */

.tabs { }

.tabs__triggers { display: flex; }
.tabs__trigger { padding: $spacing; border-bottom: 2px solid transparent; cursor: pointer; }
.tabs__trigger--active { border-color: $color-primary; }

.tabs__sheets { display: flex; overflow: hidden; margin-top: -2px; border-top: 2px solid $color-border; }
.tabs__sheet { padding: $spacing; width: 100%; flex-shrink: 0; }
.tabs__sheet--active { order: -1; }
