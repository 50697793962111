/**
 Styled text from a wysiwyg editor
 */
.wysiwyg {

  line-height: $line-height--wysiwyg;

  p { margin-bottom: 1em;  }

  a { text-decoration: underline; }

  blockquote { padding: .5em 1em; margin-left: 2em; border-left: 2px solid $color-border; font-style: italic; }
  blockquote p { margin: 0; }

  ul { @extend %list; }

  ol { @extend %list; }
  ol > li { counter-increment: ol-counter; }
  ol > li::before { content: counter(ol-counter); top: 0; font-family: $font-family--primary; font-size: 100%;  }
  ol > li > ol { counter-reset: ol-counter; }

  .button { text-decoration: none; }

}
