/**
 Cart indicator
 */

.cart-indicator { position: relative; padding: 5px 15px; border-radius: $border-radius; border: 1px solid $color-border; }
.cart-indicator:hover { color: $color-black; }
.cart-indicator[href]:hover { background: $color-blue; border-color: $color-blue; color: $color-white; }
.cart-indicator:focus { @extend %faux-input-element-base--focus; }
.cart-indicator::before { content: '\e904'; font-size: 30px; }
.cart-indicator__balloon {
  position: absolute; top: -7px; right: 8px; width: 26px; height: 26px; line-height: 26px; border-radius: 100%;
  background: $color-error; color: $color-white; font-weight: $font-weight--bold; text-align: center;
}


/**
 * Media queries
 */

@include mq__mobile {
    .cart-indicator { padding: 5px 5px; }
}
