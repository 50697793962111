@mixin mq($args...) {
  $media-type: 'only screen';
  $media-type-key: 'media-type';
  $args: keywords($args);
  $expr: '';
  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }
  @each $key, $value in $args {
    @if $value {
      $expr: "#{$expr} and (#{$key}: #{$value})";
    }
  }
  @media #{$media-type}#{$expr} {
    @content;
  }
}

@mixin mq__mobile() {
  @include mq($max-width: $breakpoint-mobile) {
    @content;
  }
}
@mixin mq__tablet() {
  @include mq($max-width: $breakpoint-tablet) {
    @content;
  }
}
@mixin mq__desktop() {
  @include mq($min-width:$breakpoint-tablet) {
    @content;
  }
}
@mixin mq__bigscreen() {
  @include mq($min-width: $breakpoint-bigscreen) {
    @content;
  }
}
@mixin ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
