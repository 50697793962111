/**
 Slider
 */
.slide { display: flex;  }
.slide__text { flex-basis: 50%; align-self: center; padding: 30px 10px 30px 30px; }
.slide__title { max-width: 480px; margin-right: 20px; margin-bottom: 1em; font-size: 44px; font-weight: $font-weight--bold; }
.slide__usps { margin: 1em 0; }
.slide__image { flex-basis: 50%; }
.slide__image img { display: block; }


/**
 * Media queries
 */

@include mq__mobile {
    .slider { margin: 0 -1*$mobile-margin; }
    .slide__text { padding: 2*$mobile-margin $mobile-margin; flex-grow: 1; }
    .slide__title { margin-right: 0; margin-bottom: 1em; max-width: none; font-size: 30px; }
    .slide__image { display: none; }

}
