/* Form elements */
[type=text],
[type=email],
[type=number],
[type=password],
[type=search],
[type=url],
[type=tel],
[type=date],
select,
textarea { @extend %input-element-base; appearance: none; }

/* Label */
label { font-size: 12px; }

/* Textarea */
textarea { min-height: 5em; max-height: 15em; height: auto; resize: vertical; line-height: $line-height--wysiwyg; }

/* Placeholder */
::placeholder { color: $color-gray--mid; }

/* Radios */
[type=radio] { position: absolute; z-index: -1; width: 1px; height: 1px; margin: 0; opacity: 0; transform: translate(5px, 5px); }
[type=radio] + label { position: relative; display: block; margin-bottom: 5px; padding-top: 2px; padding-left: 30px; font-weight: normal; cursor: pointer; font-size: 14px;}
[type=radio] + label::after { content: ''; position: absolute; top: 5px; left: 5px; width: 10px; height: 10px; border-radius: 100%; transition: .15s ease; }
[type=radio] + label::before { content: ''; position: absolute; display: block; top: 0; left: 0; width: 20px; height: 20px; border-radius: 100%; background: white; border: 2px solid $input-border-color; transition: .15s ease; }
[type=radio]:focus + label::before { border-color: $input-border-color--focus; }
[type=radio]:checked + label::after { background-color: $color-primary; }
[type=radio]:disabled + label { opacity: .5; filter: grayscale(100%); cursor: default; }

/* Checkboxes */
[type=checkbox] { @extend [type=radio]; }
[type=checkbox] + label::before { border-radius: $border-radius; }
[type=checkbox] + label::after { content: none; }
[type=checkbox]:checked + label::after { content: '\e902'; display: flex; justify-content: center; align-self: center; color: $color-secondary; font-size: 11px; border-radius: 0; background: none; }

/* Radios as buttons */
.radio-buttons {
  display: flex;

  [type=radio] + label { flex-grow: 1; position: relative; padding: 15px 10px; margin: 0 0 0 -1px; border: 1px solid $color-border; text-align: center; font-weight: $font-weight--bold;  transition: .15s ease; }
  [type=radio] + label:first-of-type { border-radius: $border-radius 0 0 $border-radius; }
  [type=radio] + label:last-of-type { border-radius: 0 $border-radius $border-radius 0; }
  [type=radio] + label:first-of-type:last-of-type { border-radius: $border-radius; }
  [type=radio] + label::after,
  [type=radio] + label::before { content: none; }

  [type=radio]:disabled + label { opacity: .5; xfilter: grayscale(100%); cursor: default; color: red; }
  [type=radio]:checked + label { background-color: $color-gray; border-color: $color-gray--dark; z-index: 1;}
  [type=radio]:focus + label { background-color: darken($color-gray, 5); border-color: $color-black; }
}

/**
 * Dropdown box (for faux select and radios in dropdown)
 */
%dropdownbox {
    position: absolute; z-index: 1; top: 100%; min-height: 0; height: auto; overflow: auto; padding: 5px 10px;
    min-width: 100%; width: auto;
    opacity: 0; transform: translateY(-10px); max-height: 0; pointer-events: none;
    transition: opacity .15s, transform .15s, max-height 0s .15s;
}
%dropdownbox--active {
    opacity: 1; transform: translateY(-1px); max-height: 50vh; pointer-events: auto;
    transition-delay: 0s, 0s, 0s;
}

/* Select */
select { padding-top: 0; padding-bottom: 0; line-height: 1.8; }
.select-box { position: relative; }
.select-box select { padding-right: 26px; }
.select-box::after { content: '\e654'; position: absolute; bottom: 13px; right: 7px; font-size: 9px; }

.select-box--multiple { position: relative; }
.select-box--multiple > label { @extend %input-element-base; padding-top: 0; padding-bottom: 0; line-height: $form-element-height; }
.select-box--multiple > select { @extend %dropdownbox; }
.select-box--multiple > select:focus { @extend %dropdownbox--active }

/* Radios in dropdown */
.choices-in-foldout { position: relative; }
.choices-in-foldout::after { content: '\e654'; position: absolute; bottom: 13px; right: 7px; font-size: 9px; }
.choices-in-foldout > label { @extend %input-element-base; padding-top: 0; padding-bottom: 0; padding-right: 25px; line-height: $form-element-height; }
.choices-in-foldout__widget { @extend %input-element-base; @extend %dropdownbox; }
.choices-in-foldout:focus .choices-in-foldout__widget,
.choices-in-foldout:focus-within .choices-in-foldout__widget { @extend %dropdownbox--active; }
