/* Form rows system */
$form-row-spacing: 6px;

%form { display: flex; flex-wrap: wrap; margin-left: -$form-row-spacing; margin-right: -$form-row-spacing; }
.form { @extend %form; }
%form__row { margin: 0 $form-row-spacing 28px; flex-basis: calc(100% - #{$form-row-spacing}); min-width: 0; }
.form__row { @extend %form__row; }

/* max width form for small forms like change password etc. */
.form--small { max-width: 350px; }

/* form__row widths set on form */
.form--2-cols .form__row { flex-basis: calc(50% - #{2*$form-row-spacing}); }
.form--3-cols .form__row { flex-basis: calc(33.333% - #{2*$form-row-spacing}); }
.form--4-cols .form__row { flex-basis: calc(25% - #{2*$form-row-spacing}); }

/* form__row widths set on form__row */
.form {
  .form__row--fill { flex-basis: auto; flex-grow: 1; }
  .form__row--100 { flex-basis: calc(100% - #{2*$form-row-spacing}); }
  .form__row--75 { flex-basis: calc(75% - #{2*$form-row-spacing}); }
  .form__row--66 { flex-basis: calc(66.666% - #{2*$form-row-spacing}); }
  .form__row--50 { flex-basis: calc(50% - #{2*$form-row-spacing}); }
  .form__row--33 { flex-basis: calc(33.333% - #{2*$form-row-spacing}); }
  .form__row--25 { flex-basis: calc(25% - #{2*$form-row-spacing}); }
  .form__row--offset-75 { margin-right: 75%; }
  .form__row--offset-50 { margin-right: 50%; }
  .form__row--offset-66 { margin-right: 66.666%; }
}

/* fieldset, used as a non-marginned wrapper for nested form__rows */
.form__fieldset { display: flex; flex-wrap: wrap; flex-basis: 100%; margin: 0; }

/* form row label */
%form-row__label { display: block; margin-bottom: 10px; font-weight: $font-weight--semi-bold; }
.form__row > label { @extend %form-row__label; }
.form__row--disabled  > label { color: $color-gray--light; }

/* form navigation */
.form__nav { @extend %form__row; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; padding-top: 1em; }
.form__nav-right-button { margin-left: auto; margin-right: 1em; }
.form__nav-primary-button,
.form__nav > *:only-child { order: 1; margin-left: auto; }

/* form errors header */
.form__errors-header { @extend %form__row; flex-basis: calc(100% - #{2*$form-row-spacing}); }

/* form row errors */
.form-row-errors { padding-top: .25em; display: block; }
.form-row-errors__error { display: inline; color: $color-error; }
.form-row-errors__error:not(:last-child) { margin-right: .5em; }

/* form__row--choice */
.form__row--choice > div > label { @extend %form-row__label; }

/* */
.form__row--checkbox-input-only label { text-indent: -999em; }

/* choice with big desc underneath */
.choice-with-desc { display: block; margin-bottom: $form-row-spacing; }
.choice-with-desc > .form__row { margin: 0; }
.choice-with-desc__desc { display: flex; justify-content: space-between; padding-left: 30px; }


/* compound widgets */
.form-widget-compound { @extend %form; }


/* single-line forms */
.form--single-line,
.form--single-line .form-widget-compound { flex-wrap: nowrap; margin-left: 0; margin-right: 0; }
.form--single-line .form__row { flex-basis: auto; margin: 0 6px 0 0; }
.form--single-line .form__row:last-child { margin-right: 0; }
.form--single-line select { white-space: nowrap; }
