.message { position: relative; display: flex; flex-direction: column; margin: $spacing 0; padding: $spacing--50 $spacing; background-color: $color-gray--light; border: solid 2px $color-border; border-radius: $border-radius; }
.message__header { font-weight: bold; }

.message--info { background-color: tint($color-info, 90%); border: 2px solid $color-info; }

.message--success { background-color: tint($color-success, 90%); border-color: $color-success; }

.message--warning { background-color: tint($color-warning, 90%); border-color: $color-warning; }

.message--error,
.message--negative { background-color: tint($color-error, 90%); border-color: $color-error; }
