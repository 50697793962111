/**
 * Shop-header
 */
.shop-header { display: flex; justify-content: space-between; align-items: center; padding: 8px 0;}
.shop-header__search { flex-basis: 100%; }
.shop-header__logo { flex-shrink: 0; margin-right: 50px; }
.shop-header__taxons { flex-shrink: 0; margin-right: 20px; }
.shop-header__cart { flex-shrink: 0; margin-left: 50px; display: flex; }


/**
 * Media queries
 */

@include mq__tablet {
  .shop-header__logo { margin-right: 20px; }
  .shop-header__cart { margin-left: 20px; }
}

@include mq__mobile {
  .shop-header__logo { max-width: 50px; margin-right: 10px; }
  .shop-header__taxons { margin-right: 10px; }
  .shop-header__search { flex-basis: auto; margin-left: auto; }
  .shop-header__cart { margin-left: 10px; }
}
